import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Shared/LottiePlayer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components/ImageCard.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components/Badge.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/Home.module.css");
